<template>
  <div class="mx-1" v-if="canVerb(resource, 'index')">
    <b-row>
      <b-col align="left">
        <b-button-group
          v-b-toggle:collapse-1
          class="my-2 filter-button-group"
          :title="getDefaultFilterMessage()"
        >
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col>
    </b-row>

    <b-collapse visible id="collapse-1">
      <b-form @submit.prevent="onSearch(filterName)">
        <b-card class="filter">
          <b-row>
            <div class="col-md-3">
              <base-select
                name="conto"
                label="Codice del Conto"
                :options="treasuries_opt"
                v-model="filter.conto"
              />
            </div>
            <div class="col-md-3">
              <base-datepicker
                name="a_partire_dal"
                label="Dal"
                v-model="filter.a_partire_dal"
              />
            </div>
            <div class="col-md-3">
              <base-datepicker
                name="fino_al"
                label="Al"
                v-model="filter.fino_al"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="produttore"
                label="Produttore"
                :options="getSalesmen()"
                v-model="filter.produttore"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="causale"
                label="Causale"
                :options="cash_references_opt"
                v-model="filter.causale"
              />
            </div>
            <div class="form-group col-md-3 align-self-end">
              <b-button
                type="submit"
                variant="lisaweb"
                size="sm"
                v-b-toggle:collapse-1
                >Cerca</b-button
              >
              <b-button
                class="btn-reset"
                type="button"
                variant="lisaweb"
                size="sm"
                @click="onClearFilter(filterName)"
                >Reset</b-button
              >
            </div>
          </b-row>
        </b-card>
      </b-form>
    </b-collapse>

    <export-options
      :exportUrl="exportUrl"
      :repository="repository"
      :resource="resource"
      :tableRef="$refs[tableRef]"
      :filter="filter"
      :options="[
        {
          code: 'EXCOGICO',
          label: null,
          formats: ['csv', 'pdf'],
        },
      ]"
    ></export-options>

    <exp-cash-entries-table
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      :filterOn="{
        byRelations: ['byBroker', 'byTreasury', 'byCashReference'],
      }"
      :ref="tableRef"
      :previous_balance_date="previous_balance_date"
      :previous_balance="previous_balance"
      :current_balance_date="filter.fino_al"
      :current_balance="current_balance"
      type="exp-cash-entry"
      noActions
    ></exp-cash-entries-table>
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
// import CashEntriesTable from "@/components/tables/CashEntry.vue";
import ExpCashEntriesTable from "@/components/tables/ExpCashEntry.vue";
import BaseSelect from "@/components/form/BaseSelect";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import { mapActions, mapGetters } from "vuex";
import { toLocaleDate } from "@/utils/dates";
import { toLocaleCurrency } from "@/utils/strings";
import { today } from "@/utils/dates";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";
import ExportOptions from "@/components/ExportOptions";
import moment from "moment";
moment.locale("it");

export default {
  mixins: [CustomFiltersMixin, StorageGetterMixin],
  data() {
    return {
      exportUrl: null,
      repository: "cash_entry",
      resource: "movimenti_prima_nota",
      tableRef: "CashEntriesExportTableRef",
      filterName: "filterCashEntriesExport",
      cash_references_opt: [],
      treasuries_opt: [],
      companies: [],
      filter: this.initFilter(),
      previous_balance_date: null,
      previous_balance: {},
      current_balance: {},
      fields: [
        {
          key: "conto.codice",
          label: this.getDictionary("Conto"),
        },
        {
          key: "data_registrazione",
          formatter: (value) => toLocaleDate(value),
          label: this.getDictionary("cash_time", "cash_entry"),
        },
        {
          key: "produttore.title",
          label: this.getDictionary("salesman"),
        },
        {
          key: "causale",
          label: this.getDictionary("cash_reference_id", "cash_entry"),
          formatter: (value) => `${value.codice} - ${value.descrizione}`,
        },
        {
          key: "descrizione",
          label: this.getDictionary("title", "cash_entry"),
        },
        {
          key: "id_compagnia",
          label: "Compagnia",
          formatter: (value) => this.retriveInsurer(value),
        },
        {
          key: "entrate",
          formatter: (value, field, item) => {
            if (item?.causale?.tipo_di_movimento === 0)
              return toLocaleCurrency(Math.abs(parseFloat(item.importo)));
            return toLocaleCurrency(0);
          },
          label: this.getDictionary("entry_in", "cash_entry"),
          tdClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
        },
        {
          key: "uscite",
          formatter: (value, field, item) => {
            if (item?.causale?.tipo_di_movimento === 1)
              return toLocaleCurrency(Math.abs(parseFloat(item.importo)));
            return toLocaleCurrency(0);
          },
          label: this.getDictionary("entry_out", "cash_entry"),
          tdClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
        },
      ],
    };
  },
  components: {
    // CashEntriesTable,
    ExpCashEntriesTable,
    BaseSelect,
    BaseDatepicker,
    ExportOptions
  },
  methods: {
    retriveInsurer(value) {
      let insurer_text = "";
      this.companies.forEach((company) => {
        if (company.value === value) insurer_text = company.text;
      });
      return insurer_text;
    },
    initFilter() {
      let init = {
        a_partire_dal: today(),
        fino_al: today(),
        produttore: null,
        causale: null,
        conto: null,
      };
      return init;
    },
    // customFilterName(key, prefix = "byAttribute") {
    //   return `${prefix}.${key}`;
    // },
    onSearch(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.exportUrl = this.$refs[this.tableRef].loadFilter(
        this.$refs[this.tableRef].filterName
      );
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      // this.$refs[this.tableRef].sortBy = "data_registrazione";
      // this.$refs[this.tableRef].sortAsc = true;
      this.$refs[this.tableRef].fetch();
    },
    fetch(name) {
      if (
        this.filter.fino_al &&
        this.filter.conto &&
        this.filter.a_partire_dal
      ) {
        let criteria = this.filter;
        this.saveFilterByName({ name, criteria });
        this.removePaginationByName(name);
        return this.$refs[this.tableRef]
          .fetch({ verb: { name: "listaMovimenti", alias: "index" } })
          .then(() => {
            this.fetchSummary();
          });
      } else {
        this.$showSnackbar({
          preset: "success",
          text: `Devi impostare i campi 'data al', 'data a' e 'codice del conto'`,
        });
      }
    },
    fetchSummary() {
      this.$refs[this.tableRef].isLoading = true;
      const Repo = RepositoryFactory.get(this.repository);
      // Previous Balance
      this.previous_balance_date = moment(this.filter.a_partire_dal)
        .subtract(1, "d")
        .format("YYYY-MM-DD");
      const byBroker = this.filter.produttore
        ? `&produttore=${this.filter.produttore}`
        : "";
      const byCashReference = this.filter.causale
        ? `&causale=${this.filter.causale}`
        : "";
      const queryString = `fino_al=${this.previous_balance_date}&conto=${this.filter.conto}${byBroker}${byCashReference}`;
      Repo.summary(queryString)
        .then((response) => {
          this.previous_balance = response.data;
          // Current Balance
          const queryString = `a_partire_dal=${this.filter.a_partire_dal}&fino_al=${this.filter.fino_al}&conto=${this.filter.conto}${byBroker}${byCashReference}`;
          Repo.summary(queryString)
            .then((response) => {
              this.current_balance = response.data;
              this.$refs[this.tableRef].isLoading = false;
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: `${errMsg}`,
              });
              this.$refs[this.tableRef].isLoading = false;
            });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.$refs[this.tableRef].isLoading = false;
        });
    },
    onClearFilter(name) {
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapGetters("auth", {
      getSalesmen: "salesmen",
      getInsurers: "insurers",
      getCashReferences: "cash_references",
      getContiPrimaNota: "allContiPrimaNota",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
  },
  created() {
    /* */
    console.log("created index");
    const path = "auth/";
    const resources = [];
    if (!this.hasLoadedResource("insurers")) {
      resources.push(
        this.$store
          .dispatch(`${path}insurers`)
          .then(() => {
            console.info(`background data "${path}insurers" fetched correctly`);
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    } else {
      this.companies = this.getInsurers();
    }
    if (resources.length) {
      this.getResources(resources)
        .then(() => {
          console.log("All promises went through correctly");
          this.companies = this.getInsurers();
        })
        .catch((error) => {
          console.log("One or more promises crashed... :|");
          this.onFailure(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.isLoading = false;
    }
    /* */
    let cashReferences = this.getCashReferences();
    cashReferences.sort((a, b) => a.text - b.text);
    cashReferences.forEach((el) => {
      this.cash_references_opt.push({
        text: `${el.text} - ${el.description}`,
        reference_type: el.reference_type,
        has_broker: el.has_broker,
        has_company: el.has_company,
        entry_type: el.entry_type,
        value: el.value,
        treasury_id: el.treasury_id,
      });
    });
    /* */
    this.treasuries_opt = this.getContiPrimaNota();
  },
  beforeMount() {
    this.removeFilterByName(this.filterName);
  },
};
</script>
